// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-behind-the-scenes-behind-the-scenes-js": () => import("../src/templates/behind-the-scenes/behind-the-scenes.js" /* webpackChunkName: "component---src-templates-behind-the-scenes-behind-the-scenes-js" */),
  "component---src-templates-mayo-community-mayo-community-js": () => import("../src/templates/mayo-community/mayo-community.js" /* webpackChunkName: "component---src-templates-mayo-community-mayo-community-js" */),
  "component---src-templates-preserving-our-culture-preserving-our-culture-js": () => import("../src/templates/preserving-our-culture/preserving-our-culture.js" /* webpackChunkName: "component---src-templates-preserving-our-culture-preserving-our-culture-js" */),
  "component---src-templates-index-page-index-page-js": () => import("../src/templates/index-page/index-page.js" /* webpackChunkName: "component---src-templates-index-page-index-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

